.TopBar{
    .LogoClicked{
        cursor: pointer;
    }
    &--Inverted{
        .column{
            svg{
                * { fill: #FFF; }
            }
        }
    }
    &.row.equal.width{
        .column{
            &:first-child{
                padding: 0px;
            }
            &:last-child{
                padding: 0px;
                text-align: right;
                div[role=listbox]{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    // min-width: 200px;
                    .DropdownUser--Select{
                        display: flex;
                        .ui.avatar.image{
                            margin: 0px;
                            margin-right: .75em;
                            width: 39px;
                            height: 39px !important;
                            max-height: 39px !important;
                        }
                        .Details{
                            strong{
                                font-size: 16.5px;
                            }
                        }
                    }
                    .dropdown.icon{
                        margin-left: 24px;
                    }
                }
                .menu{
                    .item i.icon{
                        color: #317EE0;
                    }
                }
            }
        }
    }
}

.isMobile {
    .TopBar{
        svg{
            width: 70px;
        }
    }
}